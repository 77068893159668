import React from 'react';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import PlantOrder from "@/types/PlantOrder";
import Note from "@/types/Note";
import {Card, CardActionArea, CardContent} from "@mui/material";

interface Props {
    plantOrder: PlantOrder | undefined;
    onNotesClick: (notes: Note[] | null) => void;
}

export default function PlantOrderCard({plantOrder, onNotesClick}: Props) {

    const handleClick = () => {
        onNotesClick(plantOrder?.notes ?? null)
    }

    return (<Card onClick={handleClick} sx={{marginTop: '8px'}}>
            <CardActionArea sx={{padding: 0}}>
                <CardContent sx={{padding: '8px 16px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <span style={{fontWeight: 'bold', fontSize: '1rem'}}>{plantOrder?.comment} - {plantOrder?.type}</span>
                    {(plantOrder?.notes?.length ?? 0) > 0 ?
                        <SpeakerNotesIcon style={{fontSize: 30, color: 'orange'}} /> : null
                    }
                </CardContent>
            </CardActionArea>
        </Card>

    );
};