import {Dialog, DialogContent, DialogTitle, Typography} from "@mui/material";
import moment from "moment";
import Note from "@/types/Note";

interface Props {
    notes: Note[] | null;
    onClose: (refresh: boolean) => void
}

export default function PlantOrderNotesModal({notes, onClose}: Props) {
    return (
        <Dialog open={!!notes} onClose={() => onClose(false)} maxWidth="sm" fullWidth>
            <DialogTitle>
                Bloei notities
            </DialogTitle>
            <DialogContent style={{padding: '12px', overflowY: "auto", maxHeight: '500px' }} dividers>
                {notes?.map((note, index) => (
                    <div key={index} style={{ marginTop: 5}}>
                        <Typography variant="caption" color="text.secondary">
                            {moment(note.created_at).fromNow()}
                        </Typography>
                        <div
                            style={{
                                backgroundColor: "#e8ecee",
                                padding: 12,
                                borderRadius: 5,
                                wordBreak: "break-word",
                                marginTop: 2,
                            }}
                        >
                            {note.description}
                        </div>
                    </div>
                ))}
            </DialogContent>
        </Dialog>
    )
}