import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useEffect, useState} from "react";
import {MdOutlineClose} from "react-icons/md";
import {COMMENT_TYPE} from "./CommentTypesConstant";
import {StaticSelectField, TextareaField} from "@meierij-it/react-components-v2";
import {useForm} from "react-hook-form";
import axiosInstance from "@/features/auth/authService";
import moment from "moment";

interface WorkOrder {
    id: number;
    title: string;
    description: string;
    client_id: number;
    contact: string;
    phone: string;
    street: string;
    postal_code: string;
    city: string;
    houseNumber: string;
    contract: string;
    date_visit: string;
    date_last_visit: string;
    period: number;
    week: number;
    route_number: number;
    start_time: string;
    end_time: string;
    general_remarks: string;
    planters_big: number;
    planters_small: number;
    number_of_planters: number;
    contract_type: string;
    gve_count: number;
    customer_comment: string;
    reference: string;
    entity_uuid: string;
    mail_sent: boolean;
    uuid: string;
    confirmation_mail_address: string;
}

interface IProps {
    workOrder: WorkOrder
    onClose: () => void;
}

export default function WorkOrderCommentsDialog({onClose, workOrder}: IProps) {

    const [comments, setComments] = useState<any[]>([])

    const {
        control,
        handleSubmit,
        reset
    } = useForm()

    useEffect(() => {
        fetchComments()
    }, [])

    const fetchComments = () => {
        axiosInstance.get(`/work-orders/${workOrder.uuid}/comments/`).then((response) => {
            setComments(response.data)
        }).catch((error) => console.log(error))
    }

    const commentsElement = comments.map((comment: any) => {
        const created_at = moment(comment.created_at); // Use moment to parse the date
        return <>
            <span
                style={{color: '#6d6d6d'}}><b>{comment.employee_name ? `${comment.employee_name} | ` : null}</b> {COMMENT_TYPE[comment.type]}</span><br/>
            <div style={{
                padding: '12px',
                border: '1px solid #cdcdcd',
                background: 'white',
                color: 'black',
                borderRadius: '5px',
            }} key={comment.created_at}>
                <span>{comment.comment}</span><br/><br/>
                <span style={{
                    color: '#6d6d6d',
                    display: 'flex',
                    justifyContent: 'space-between'
                }}><b>{created_at.format('DD-MM-YYYY')}</b>
                <b>{created_at.format('HH:mm')}</b></span>
            </div>
            <br/>
        </>
    })

    const addComment = (data: any) => {
        const hydrated = {
            ...data,
            type: data.type.value
        }
        axiosInstance.post(`/work-orders/${workOrder.uuid}/add_comment/`, hydrated).then(() => {
            reset({})
            fetchComments()
        }).catch((error) => console.log(error))
    }

    return <Dialog open={true} onClose={onClose} fullWidth>
        <DialogTitle sx={{display: 'flex', justifyContent: 'space-between'}}>
            <span>{workOrder.description}</span>
            <Button onClick={onClose}><MdOutlineClose style={{fontSize: '1.5rem', color: 'black'}}/></Button>
        </DialogTitle>
        <DialogContent dividers sx={{height: '500px', overflowY: 'scroll', background: '#efefef'}}>
            {commentsElement}
        </DialogContent>
        <DialogActions sx={{display: 'flex', flexDirection: 'column'}}>
            <p><b>Opmerking toevoegen</b></p>
            <form style={{width: '100%'}} onSubmit={handleSubmit(addComment)}>
                <TextareaField isRequired name={'comment'} control={control} label={'Opmerking'}/>
                <br/>
                <br/>
                <StaticSelectField isRequired name={'type'} control={control} label={'Type'} options={[
                    {label: 'Opmerking op werkbon', value: 'COMMENT_ON_RECEIPT'},
                    {label: 'Opmerking volgend bezoek', value: 'COMMENT_NEXT_VISIT'},
                    {label: 'Opmerking voor kantoor', value: 'COMMENT_OFFICE'}
                ]}/>
                <br/>
                <br/>
                <Button type={'submit'} variant={'contained'} sx={{width: '100%'}}>Opslaan</Button>
            </form>
        </DialogActions>
    </Dialog>
}