import PageLayout from "../../components/MIT/PageLayout";
import {CircularProgress, Fab} from "@mui/material";
import AlarmAddIcon from '@mui/icons-material/AlarmAdd';
import {Link, useNavigate} from "react-router-dom";
import ClickableListItem from "../../components/MIT/ClickableListItem";
import React, {useCallback, useEffect, useState} from "react";
import axiosInstance from "@/features/auth/authService";
import moment from "moment";
import FlagIcon from '@mui/icons-material/Flag';
import PlantOrder from "@/types/PlantOrder";
import {PiPlantLight} from "react-icons/pi";


interface PlanningRow {
    uuid?: string;
    work_order?: string;
    route_number?: number;
    entity_name?: string;
    entity_period?: string;
    employee_primary_initials?: string;
    employee_secondary_initials?: string;
    employee_primary?: string;
    employee_secondary?: string;
    status?: string;
    entity_address?: any;
    entity_contacts?: any[];
    work_order_comment?: string;
    work_order_end_time?: string;
    vehicle_description?: string;
    plant_orders?: PlantOrder[];
    remarks_last_visit?: string;
    work_order_mail_sent?: boolean;
    contains_plants_or_bloom?: boolean;
}

interface Day {
    date?: Date;
    workOrders?: PlanningRow[];
}

export default function WorkOrders() {

    const [workOrders, setWorkOrders] = useState<Day[]>([])
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {
        // if (!UUID) return;
        setLoading(true)
        const startOfWeek = moment().startOf('isoWeek').format('YYYY-MM-DD');
        const endOfFollowingWeek = moment().endOf('isoWeek').format('YYYY-MM-DD');
        axiosInstance.get(`planning-entries/compact_list/?date_from=${startOfWeek}&date_to=${endOfFollowingWeek}`).then(({data}) => {

            const groupedByDate = data.reduce((acc: any, obj: any) => {
                const date = obj.date_visit;
                if (!acc[date]) {
                    acc[date] = [];
                }
                acc[date].push(obj);
                return acc;
            }, {});

            const workOrders = Object.keys(groupedByDate).map(date => ({
                date: new Date(date),
                workOrders: groupedByDate[date],
            }));

            setWorkOrders(workOrders)

        }).catch((error) => {
            console.log(error)
        }).finally(() => setLoading(false));
    }, [])

    useEffect(() => {
        const today = moment().format('DD-MM-YY')
        document.getElementById(today)?.scrollIntoView()
    }, [workOrders])

    const handleWorkOrderClick = useCallback((workOrderId: string) => {
        navigate(`/workorders/${workOrderId}`)
    }, []);

    const mappedWorkOrders = workOrders.map((planningRow: Day) => {

        const planningListItems = planningRow.workOrders?.map((workOrder: PlanningRow) => <ClickableListItem
            key={workOrder.uuid}
            onClick={() => {
                if (!workOrder.work_order) {
                    axiosInstance.post('work-orders/create_for_planning_entry/', {
                        planning_entry_uuid: workOrder.uuid
                    }).then((response) => {
                        if (response.status === 201 || response.status === 200) {
                            handleWorkOrderClick(response.data)
                        }
                    })
                } else {
                    handleWorkOrderClick(workOrder?.work_order)
                }
            }}>
            <div style={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                <span style={{position: 'relative', width: '100%'}}>
                    <span style={{fontWeight: 'bold', fontSize: '1.1rem'}}>{workOrder.entity_name}</span><br/>
                    {workOrder.entity_address?.address_composite}<br/>
                    {workOrder.vehicle_description ? <>Bus: {workOrder.vehicle_description}<br/></> : null}
                    {workOrder.entity_contacts?.map((contact: any) => `${contact.first_name} ${contact.last_name}`).join(', ')}
                    <div style={{
                        alignItems: 'center',
                        color: '#f19700',
                        marginTop: '8px',
                        background: '#f0f0f0',
                        padding: '4px'
                    }}>
                    {workOrder?.plant_orders?.length ?? 0 > 0 ?
                        <>
                            <span style={{fontWeight: 'bold'}}>Bloei / Plantvervangingen: </span>
                            {workOrder.plant_orders?.map((plantOrder: PlantOrder) => {
                                return <p>{plantOrder.comment} - {plantOrder.type}</p>
                            })}
                        </>
                        : null}
                        {workOrder?.remarks_last_visit ?
                            <>
                                <span style={{fontWeight: 'bold'}}>Opmerking vorig bezoek: </span>
                                <p>{workOrder?.remarks_last_visit}</p>
                            </>
                            : null}

                    </div>
                </span>
                <FlagIcon
                    sx={{color: workOrder.status === 'VISITED' ? (workOrder.work_order_end_time && workOrder.work_order_mail_sent  ? 'green' : 'orange') : 'red'}}/>
                {workOrder.contains_plants_or_bloom ?
                <PiPlantLight size={24} style={{position: 'absolute', top: '8px', right: '0', color: 'green'}}/>
                    : null}
            </div>

        </ClickableListItem>)

        return <div key={planningRow.date?.toString()}>
            <h3 id={moment(planningRow.date).format('DD-MM-YY')}>{moment(planningRow.date).format('dddd DD-MM-YY')}</h3>
            {planningListItems}
        </div>

    });

    return <PageLayout title={'Home'} backButton={false}>
        {loading ? <CircularProgress/> :
            <div className={'container'}>
                {mappedWorkOrders}

                <Link to={'/hours'}>
                    <Fab sx={{position: 'fixed', bottom: '32px', right: '32px'}} color="primary"
                         aria-label="Uren klokken">
                        <AlarmAddIcon/>
                    </Fab>
                </Link>
            </div>
        }

    </PageLayout>
}