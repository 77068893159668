import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {useForm} from "react-hook-form";
import {InputField} from "@meierij-it/react-components-v2";
import React, {useEffect, useState} from "react";
import axiosInstance from "@/features/auth/authService";
import moment from "moment";
import {MobileTimePicker} from "@mui/x-date-pickers";

interface Props {
    workOrderId: string | null;
    onClose: (refresh: boolean) => void
}

export default function EditTimeDialog({workOrderId, onClose}: Props) {

    const [startTime, setStartTime] = useState<string | null>(null)
    const [endTime, setEndTime] = useState<string | null>(null)

    useEffect(() => {
        if (workOrderId) {
            // Fetch data
            axiosInstance.get(`/work-orders/${workOrderId}`).then(response => {
                const {start_time, end_time} = response.data
                setStartTime(start_time ? moment(start_time, 'HH:mm:ss').format('HH:mm') : '')
                setEndTime(end_time ? moment(end_time, 'HH:mm:ss').format('HH:mm') : '')
            })
        }
    }, [workOrderId])

    const doSave = () => {
        axiosInstance.patch(`/work-orders/${workOrderId}/`, {
            start_time: startTime ? moment(startTime, 'HH:mm').format('HH:mm:ss') : null,
            end_time: endTime ? moment(endTime, 'HH:mm').format('HH:mm:ss') : null
        }).then(() => {
            onClose(true)
        })
    }

    return <Dialog open={!!workOrderId} onClose={() => onClose(false)} maxWidth="sm" fullWidth>
        <DialogTitle>
            Tijden aanpassen
        </DialogTitle>
        <DialogContent style={{padding: '8px'}} dividers>
            <div style={{margin: '8px'}}>
                <MobileTimePicker sx={{width: '100%'}} format={"HH:mm"} ampm={false}
                                  label={'Starttijd (uren:minuten)'}
                                  value={startTime ? moment(startTime, 'HH:mm') : null}
                                  onChange={(newValue) => {
                                      setStartTime(newValue ? newValue.format('HH:mm') : null)
                                  }}
                />
            </div>
            <div style={{margin: '8px'}}>
                <MobileTimePicker sx={{width: '100%'}} format={"HH:mm"} ampm={false}
                                  label={'Eindtijd (uren:minuten)'}
                                  value={endTime ? moment(endTime, 'HH:mm') : null}
                                  onChange={(newValue) => {
                                      setEndTime(newValue ? newValue.format('HH:mm') : null)
                                  }}
                /></div>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onClose(false)}>Annuleren</Button>
            <Button onClick={doSave} variant={'contained'} color={'success'}>Opslaan</Button>
        </DialogActions>

    </Dialog>

}